<template>
  <div>
    <div
      v-if="mobileVerificationErrorForShoppingPopupShopping"
      class="error-message"
    >
      {{ mobileVerificationErrorForShopping }}
      <a href="/profile">
        <button class="verify-button">Verify Now</button>
      </a>
    </div>
    <div v-if="identityVerificationErrorPopupShopping" class="error-message">
      {{ mobileVerificationErrorForShopping }}
    </div>
    <v-card elevation="1">
      <v-card-actions>
        <!-- <v-toolbar elevation="0"> -->
        <v-card-title
          class="pl-1 py-0 font-weight-regular"
          v-if="cartList.length != 0"
        >
          <span v-if="flag"
            >Review and agree to end user registration requirements and notes
            :-</span
          >
        </v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2 d-none d-sm-flex"
          fab
          small
          dark
          color="blue"
          elevation="2"
          to="/purchasedid"
        >
          <v-icon @click="deleteItem()"> keyboard_backspace </v-icon>
        </v-btn>
      </v-card-actions>
      <!-- </v-toolbar> -->
      <v-divider></v-divider>

      <div v-if="cartList.length < 1" class="py-15">
        <v-row align="center" justify="center">
          <v-card-title>Your shopping cart is empty.</v-card-title>
        </v-row>
        <v-row align="center" justify="center">
          <v-card-actions>
            <v-btn
              depressed
              class="primary-btn"
              outlined
              to="/purchasedid"
              @click="deleteGeoId()"
            >
              Continue Shopping
            </v-btn>
          </v-card-actions>
        </v-row>
      </div>

      <v-dialog v-model="showDialog" max-width="400px" persistent>
        <v-card flat class="Card-style pa-2">
          <v-container fluid>
            <v-layout justify-center>
              <v-flex xs12 sm12>
                <p>Would You like to remove your order?</p>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="showDialog = false" depressed color="error"
                    >Cancel</v-btn
                  >
                  <v-btn @click="modifyCart" depressed color="primary">
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showClearDialog" max-width="400px" persistent>
        <v-card flat class="Card-style pa-2">
          <v-container fluid>
            <v-layout justify-center>
              <v-flex xs12 sm12>
                <p>Would You like to clear your All order?</p>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="showClearDialog = false"
                    depressed
                    color="error"
                    >Cancel</v-btn
                  >
                  <v-btn @click="destroyCart" depressed color="primary">
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showCheckoutDialog" max-width="400px" persistent>
        <v-card flat class="Card-style pa-2">
          <v-container fluid>
            <v-layout justify-center>
              <v-flex xs12 sm12>
                <p>
                  Order has been placed successfully. We will inform you via
                  Email when numbers are allocated.
                </p>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="closeCheckOutDialog" depressed color="primary"
                    >OK
                  </v-btn>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-dialog>

      <v-dialog v-model="userFormDialog" max-width="900" persistent>
        <!-- <div v-for="(country, i) in countryNameList" :key="i"> -->
        <v-card flat class="Card-style pa-2" :loading="submitLoader">
          <v-layout>
            <v-flex xs12 class="pa-2">
              <label class="title text-xs-center"
                >Upload Documents
                <!-- <span class="text-capitalize" v-if="country.countryName"
                    >For {{ country.countryName }}</span
                  > -->
              </label>
              <v-icon
                style="float: right"
                class="right"
                @click="userFormDialog = false"
                >cancel</v-icon
              >
            </v-flex>
          </v-layout>
          <v-divider></v-divider>
          <v-container fluid>
            <v-radio-group
              v-model="radioGroup"
              label="Type:"
              row
              @change="changeForm()"
            >
              <v-radio label="Personal" value="PERSONAL"></v-radio>
              <v-radio label="Business" value="BUSINESS"></v-radio>
            </v-radio-group>
          </v-container>
          <!-- alerts message when aprroved document -->
          <v-col
            lg="12"
            md="12"
            sm="12"
            cols="12"
            class="py-0"
            v-if="userDocumentInfo.statusId == 'DOC_APPROVED'"
          >
            <v-alert border="right" color="blue-grey" dark>
              This Identity is approved, so no changes can be performed. If you
              need to allocate number with Different identity , Kindly create a
              new identity with new information.
            </v-alert>
          </v-col>
          <v-col cols="12" class="pb-0">
            <!-- personal form -->
            <v-card-subtitle class="pl-0 pb-3 pt-2"
              ><b>Identity Name:-</b>
            </v-card-subtitle>
            <v-row>
              <v-col cols="12" sm="4" class="py-0">
                <v-select
                  label="Select Documents"
                  :items="getDocumentList"
                  v-model="document.documentInfoId"
                  item-text="name"
                  item-value="documentInfoId"
                  color="primary"
                  outlined
                  dense
                  hide-details
                >
                  <template slot="item" slot-scope="data">
                    <v-list-item-content @click="getItemText(data.item)">
                      <v-list-item-title>{{
                        data.item.name
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-card-subtitle class="pl-1 pb-3"
              ><b>Personal Information:-</b>
            </v-card-subtitle>
            <v-form ref="form" v-model="isFormValid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="First name"
                    v-model="userDocumentInfo.firstName"
                    color="primary"
                    :rules="firstNameRules"
                    :counter="maxCharacters"
                    outlined
                    dense
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Middle name"
                    :rules="middleNameRules"
                    v-model="userDocumentInfo.middleName"
                    :counter="maxCharacters"
                    color="primary"
                    outlined
                    dense
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Last name"
                    v-model="userDocumentInfo.lastName"
                    :counter="maxCharacters"
                    color="primary"
                    :rules="lastNameRules"
                    outlined
                    dense
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="4" class="py-0">
                  <v-select
                    :items="countryCodeOptions"
                    label="Country"
                    v-model="userDocumentInfo.countryCode"
                    item-text="name"
                    item-value="code"
                    outlined
                    dense
                  ></v-select>
                </v-col> -->
                <v-col cols="12" sm="4" class="py-0">
                  <v-select
                    :items="countryCodeOptions"
                    label="Country Code"
                    v-model="userDocumentInfo.countryCode"
                    item-text="name"
                    item-value="code"
                    outlined
                    dense
                    :rules="countryCodeRules"
                    :disabled="
                      userDocumentInfo.statusId == 'DOC_APPROVED' &&
                      !showCountryCode
                    "
                  >
                    <template v-slot:item="data">
                      <div class="d-flex align-center">
                        <img
                          :src="getFlagURL(data.item.iso2)"
                          class="mr-2"
                          alt="flag"
                        />
                        <span>{{ data.item.name }}</span>
                        <span class="ml-auto">{{ data.item.code }}</span>
                      </div>
                    </template>
                    <template v-slot:selection="data">
                      <div class="d-flex align-center">
                        <img
                          :src="getFlagURL(data.item.iso2)"
                          class="mr-2"
                          alt="flag"
                        />
                        <span>{{ data.item.name }}</span>
                        <span class="ml-auto">{{ data.item.code }}</span>
                      </div>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    type="number"
                    label="Phone Number"
                    v-model="userDocumentInfo.contactNumber"
                    color="primary"
                    :counter="15"
                    :rules="mobileNumberRules"
                    outlined
                    dense
                    :disabled="
                      userDocumentInfo.statusId == 'DOC_APPROVED' &&
                      !showPhoneNumber
                    "
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-menu
                    ref="dateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Date Of Birth"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        readonly
                        dense
                        :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                        v-model="userDocumentInfo.dateOfBirth"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="userDocumentInfo.dateOfBirth"
                      no-title
                      :max="maxDate"
                      @input="dateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Personal Tax ID "
                    :rules="taxIdRules"
                    v-model="userDocumentInfo.personalTaxId"
                    color="primary"
                    counter="18"
                    outlined
                    dense
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Address"
                    v-model="userDocumentInfo.address"
                    color="primary"
                    :rules="addressRules"
                    counter="82"
                    outlined
                    dense
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="City"
                    v-model="userDocumentInfo.city"
                    :rules="cityRules"
                    counter="15"
                    color="primary"
                    outlined
                    dense
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Country"
                    v-model="userDocumentInfo.country"
                    :rules="countryRules"
                    color="primary"
                    outlined
                    dense
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="ZIP Code or Postal Code"
                    v-model="userDocumentInfo.postalCode"
                    color="primary"
                    :rules="postalCodeRules"
                    counter="9"
                    outlined
                    dense
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-text-field
                    label="Country of Birth"
                    v-model="userDocumentInfo.countryOfBirth"
                    color="primary"
                    :rules="countryOfBirthRules"
                    counter="25"
                    outlined
                    dense
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <div v-if="radioGroup == 'BUSINESS'">
                <v-card-subtitle class="pl-1 pb-3"
                  ><b>Business Information:- </b></v-card-subtitle
                >
                <v-row>
                  <v-col cols="12" sm="4" class="py-0">
                    <v-text-field
                      label="Company name"
                      v-model="userDocumentInfo.companyName"
                      color="primary"
                      :rules="businessNameRules"
                      outlined
                      dense
                      :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4" class="py-0">
                    <v-text-field
                      label="Company registration number"
                      :rules="companyRegistrationRules"
                      v-model="userDocumentInfo.companyRegistrationNumber"
                      outlined
                      dense
                      :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="py-0">
                    <v-text-field
                      label="Incorportation Country"
                      :rules="incorportationCountryRules"
                      v-model="userDocumentInfo.incorporationCountry"
                      color="primary"
                      outlined
                      dense
                      :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" class="py-0">
                    <v-text-field
                      label="VAT ID"
                      :rules="vatIdRules"
                      v-model="userDocumentInfo.vatId"
                      color="primary"
                      outlined
                      hide-details
                      dense
                      :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-form>

            <!-- get uploaded files Description -->
            <section v-if="outList.length != 0">
              <v-card-subtitle class="pl-1 pb-3"
                ><b>Uploaded Documents:-</b>
              </v-card-subtitle>
              <!-- <v-btn
                  text
                  v-for="(files, i) in outList"
                  :key="i"
                  :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  @click="deleteDialog(files)"
                >
                  {{ files.description }}
                  <v-icon color="red" size="18">delete</v-icon>
                </v-btn> -->
              <v-chip
                class="ma-2"
                @click="deleteDialog(files)"
                v-for="(files, i) in outList"
                :key="i"
                :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
              >
                {{ files.description }}
                <v-icon color="red" size="18" class="ml-2"> cancel </v-icon>
              </v-chip>
            </section>

            <!-- upload files -->
            <v-card-subtitle class="pl-1 pb-3"
              ><b>Upload Documents:- </b> File extension should be .jpeg, .jpg,
              .pdf or .png.
            </v-card-subtitle>
            <v-form v-model="isDisabled">
              <v-row v-for="(input, index) in uploadInfoInputs" :key="index">
                <v-col cols="12" sm="4" class="py-0">
                  <v-select
                    label="Select Document"
                    :items="documentList"
                    item-text="text"
                    item-value="value"
                    v-model="input.uploadFileKey"
                    color="primary"
                    outlined
                    @change="uploadFiles(input.uploadFileKey)"
                    dense
                    clearable
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-file-input
                    label="upload"
                    color="primary"
                    append-icon="mdi mdi-paperclip"
                    v-model="input.uploadFile"
                    :rules="FileUploadrules"
                    outlined
                    @change="uploadFiles(input.uploadFile)"
                    dense
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                  </v-file-input>
                </v-col>
                <v-col cols="12" sm="4" class="pa-0">
                  <!-- <span>
                      <v-btn
                        outlined
                        color="primary"
                        v-if="isDisabled"
                        :loading="fileLoader"
                        @click="uploadBase64()"
                      >
                        <v-icon> upload </v-icon>Upload</v-btn
                      >
                    </span> -->
                  <v-btn
                    fab
                    outlined
                    small
                    color="primary"
                    @click="addRow(1)"
                    class="ml-2"
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                  >
                    <v-icon> add </v-icon></v-btn
                  >
                  <v-btn
                    v-if="uploadInfoInputs.length != 1"
                    fab
                    outlined
                    class="ml-3"
                    small
                    color="error"
                    @click="deleteRow(index, input)"
                    :disabled="userDocumentInfo.statusId == 'DOC_APPROVED'"
                    ><v-icon>delete</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="userFormDialog = false"
                depressed
                color="error"
                elevation="0"
                class="mt-2"
                >Cancel</v-btn
              >
              <!-- <v-btn
                  depressed
                  color="primary"
                  class="mt-2"
                  :disabled="!isDisabled || !isFormValid"
                  :loading="submitLoader"
                  @click="
                    nextToSaveData(
                      i == countryNameList.length - 1 ? 'Submit' : '',
                      i,
                      country
                    )
                  "
                  elevation="0"
                >
                  <span
                    v-html="i == countryNameList.length - 1 ? 'Submit' : 'Next'"
                  ></span>
                </v-btn> -->
              <v-btn
                depressed
                color="primary"
                class="mt-2"
                :disabled="!isDisabled || !isFormValid"
                :loading="submitLoader"
                @click="submitUserForm(userDocumentInfo.documentInfoId)"
                elevation="0"
              >
                Submit
              </v-btn>
              <!-- <v-btn
                v-if="i != countryNameList.length - 1"
                depressed
                color="primary"
                class="mt-2"
                :loading="submitLoader"
                @click="nextToSaveData"
                elevation="0"
              >
                Next
              </v-btn> -->
            </v-card-actions>
          </v-col>
        </v-card>
        <!-- </div> -->
      </v-dialog>

      <v-container class="fill-height pb-6" fluid v-if="cartList.length > 0">
        <v-row v-if="instructions.length != 0">
          <v-col lg="12" md="12" sm="12" cols="12" v-if="flag">
            <div v-for="(instruction, i) in instructions" :key="i">
              <v-alert border="right" color="blue-grey" dark v-if="instruction">
                <span v-html="instruction"></span>
              </v-alert>
            </div>
          </v-col>
          <!-- <v-col lg="12" md="12" sm="12" cols="12">
            <v-card-actions class="py-0">
              <v-spacer></v-spacer>
              <v-btn
                class="primary-btn"
                outlined
                @click="userFormDialog = true"
              >
                Upload Documents
                <v-icon> cloud_upload </v-icon>
              </v-btn>
            </v-card-actions>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-card-title class="pl-0 py-0 font-weight-regular">
                    Order Summary :-
                  </v-card-title>
                </v-col>
                <v-col cols="12" sm="6" class="text-right">
                  <v-btn
                    v-if="instructionCount && instructionCount > 0"
                    class="primary-btn"
                    outlined
                    @click="fetchDocumentInfoIdList()"
                  >
                    <span v-if="selectedIdentity"
                      >Edit Documents - {{ selectedIdentity }}</span
                    >
                    <span v-else> Upload Documents</span>
                    <v-icon class="pl-2"> cloud_upload </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="pt-0 mt-0">
                  <v-card elevation="1">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <!-- <th class="text-left">DID</th>

                            <th class="text-left">Quantity</th>
                            <th class="text-left">
                              <v-icon>call_to_action </v-icon>
                            </th> -->
                            <th class="text-left">DID Numbers</th>
                            <th class="text-left">
                              {{ totalDIDNumbers }}
                            </th>
                            <!-- <th>Update Item</th> -->
                            <th class="text-left">Delete Item</th>

                            <!-- <th class="text-left">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon color="red" @click="showClearModal()" v-on="on">
                                    mdi-cart
                                  </v-icon>
                                </template>
                                <span>Clear cart</span>
                              </v-tooltip>
                            </th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in cartList"
                            :key="item.itemIndex"
                          >
                            <td class="py-2">
                              <b v-if="item.cityName">
                                {{ item.countryName }} ({{ item.cityName }} -
                                {{ item.areaCode }})
                              </b>
                              <b v-else>
                                {{ item.countryName }} ({{ item.areaCode }})
                              </b>
                              <br />
                              Setup:<b> ${{ item.setupCost }}</b> <br />
                              Unitprice: <b> ${{ item.unitPrice }}</b> <br />
                              DidType: <b>{{ item.didType }}</b>
                            </td>
                            <!-- <td style="padding: 8px; text-align: left;"> -->
                            <td>
                              {{ Number(item.quantity) }}

                              <!-- <input type="number" v-model="item.quantity"
                                style="width: 50px; padding: 5px; border: 1px solid #ddd; border-radius: 3px; box-sizing: border-box;"
                                min="1" max="100" pattern="[1-9][0-9]*"> -->
                              <!-- <input type="number" id="quantityInput" :value="item.quantity" style="width: 50px; padding: 5px; border: 1px solid #ddd; border-radius: 3px; box-sizing: border-box;" min="1" max="100" pattern="[1-9][0-9]*"> -->
                            </td>
                            <!-- <td>
                            {{ Number(item.quantity) }}
                            </td> -->
                            <!-- Update Item Button -->
                            <!-- <td>
                              <v-icon @click="updateCart(index, item.quantity)">mdi-pencil</v-icon>
                            </td> -->
                            <td>
                              <v-icon
                                color="red"
                                @click="showModal(index, item.quantity)"
                                >delete</v-icon
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <!-- Total DID Orders -->
                    <v-divider></v-divider>
                    <v-card-actions class="pt-1 pb-0">
                      <v-card-subtitle class="pl-2 black--text"
                        >Total DID Orders</v-card-subtitle
                      >
                      <v-spacer></v-spacer>
                      <v-card-subtitle class="black--text">
                        ${{ grandTotal }}
                      </v-card-subtitle>
                    </v-card-actions>
                  </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="pt-0 mt-0">
                  <v-card elevation="1">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Pay By</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="py-4">
                              <v-radio-group
                                v-model="billingAccountId"
                                mandatory
                              >
                                <v-radio
                                  :value="account.billingAccountId"
                                  v-for="account of accountBillingList"
                                  :key="account.billingAccountId"
                                >
                                  <template v-slot:label>
                                    <div>
                                      Billing Account: ${{ account.balance }}
                                    </div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-divider></v-divider>
                    <v-card-actions class="py-3">
                      <v-row align="end" justify="end" no-gutters>
                        <v-btn class="primary-btn" to="billingAcc" outlined>
                          Recharge</v-btn
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                          class="primary-btn"
                          outlined
                          @click="checkOut"
                          :disabled="showCHeckout"
                        >
                          Check Out</v-btn
                        >
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <loading :active="isLoading" :loader="loader" />
    </v-card>

    <!-- dialog delete -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title>Are you sure you want to delete this?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import shoppingCartAPI from "@/services/shoppingCartAPI.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import moment from "moment";
import { countryCodeOptions, getFlagURL } from "./countryCodeOptionsList";
import profileAPI from "@/services/profileAPI.js";

export default {
  name: "shoppingCart",
  components: {
    Loading,
  },
  data() {
    return {
      mobileVerificationErrorForShopping: "",
      mobileVerificationErrorForShoppingPopupShopping: false,
      identityVerificationErrorPopupShopping: false,
      userData: {},
      showCHeckout: true,
      moment: moment,
      countryNames: "",
      radioGroup: "PERSONAL",
      cartList: [],
      accountBillingList: [],
      grandTotal: 0,
      totalDIDNumbers: 0,
      instructions: {},
      billingAccountId: null,
      removeCartData: {},
      showDialog: false,
      showClearDialog: false,
      showCheckoutDialog: false,
      userFormDialog: false,
      userDetail: {},
      isLoading: false,
      loader: "bars",
      imageName: "",
      imageUrl: "",
      imageFile: "",
      type: "",
      maxCharacters: 70,
      userDocumentInfo: {
        // documentInfoId: "",
      },
      getDocumentList: [],
      IndentiyDocumentList: [
        {
          name: "Create New Identity",
          documentInfoId: "createNew",
        },
      ],
      // userDocumentInfo:[],
      documentList: [
        {
          value: "BUS_REG_CER",
          text: "Business Registration Certificate",
        },
        {
          value: "CO_REN_CER",
          text: "Company renewal Certificate",
        },
        {
          value: "PASSPORT",
          text: "Passport",
        },
        {
          value: "NAT_ID_PRF",
          text: "National ID proof",
        },
        {
          value: "DL",
          text: "Driving Licence",
        },
        {
          value: "OTH_ID_PRF",
          text: "Other ID Proof",
        },

        {
          value: "UTL_BILL",
          text: "Utility Bill",
        },
        {
          value: "TELCOM_BILL",
          text: "Telecom Bill",
        },
        {
          value: "RE_BANK_ST",
          text: "Recent Bank Statement",
        },
        {
          value: "RENT_LEASE_AGT",
          text: "Rent/Lease Agreement",
        },
        {
          value: "OTH_ADDRESS_PRF",
          text: "other Address proof",
        },
        {
          value: "OTHERS",
          text: "Others",
        },
      ],
      isFormValid: true,
      isDisabled: true,
      submitLoader: false,
      taxIdRules: [
        (v) => (v && v.length >= 18 ? "Maximum 18 characters allowed" : true),
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      firstNameRules: [
        (v) => !!v || "Field is reuired",
        (v) => (v && v.length <= 70) || "Maximum 70 characters allowed",
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      middleNameRules: [
        (v) => (v && v.length >= 70 ? "Maximum 18 characters allowed" : true),
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      lastNameRules: [
        (v) => !!v || "Field is reuired",
        (v) => (v && v.length <= 70) || "Maximum 70 characters allowed",
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      mobileNumberRules: [
        (v) => !!v || "Field is required", // Check if the field is filled
        (v) => (v && v.length >= 8) || "Minimum 8 digits required", // Minimum length check
        (v) => (v && v.length <= 15) || "Maximum 15 digits allowed", // Maximum length check
        (v) => /^\d+$/.test(v) || "Only numeric values are allowed", // Only numeric check
      ],
      countryCodeRules: [
        (v) => !!v || "Field is required", // Check if the field is filled
        // (v) => v.length > 0 || "Please select a country code"  // Ensures a country code is selected
      ],
      addressRules: [
        (v) => !!v || "Field is reuired",
        (v) => (v && v.length <= 82) || "Maximum 82 characters allowed",

        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      cityRules: [
        (v) => !!v || "this value is required",
        (v) => (v && v.length <= 15) || "Maximum 15 characters allowed",
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      countryRules: [
        (v) => !!v || "this value is required",
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      postalCodeRules: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length <= 9) || "Maximum 9 characters allowed",
        (v) =>
          /^[a-zA-Z0-9\-\s- ]{0,9}$/.test(v) ||
          "pin code can only contain letters, numbers, spaces and hyphens",
      ],
      // countryOfBirthRules: [
      //   (v) => (v && v.length >= 9 ? "Maximum 18 characters allowed" : true),
      //   (v) =>
      //     /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      // ],
      businessNameRules: [
        (v) => !!v || "Field is required",
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      vatIdRules: [
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      companyRegistrationRules: [
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      incorportationCountryRules: [
        (v) =>
          /[^\w\s]/.test(v) ? "Special characters are not allowed" : true,
      ],
      FileUploadrules: [
        (v) =>
          !v ||
          v.size <= 3000000 ||
          "file size should be less than or equal to 3 Mb!",
      ],
      countryOfBirthRules: [
        (v) => !!v || "Field is required", // Check if the field is filled
        (v) => (v && v.length <= 25) || "Maximum 25 characters allowed", // Maximum length check
        (v) => /^[^\d]+$/.test(v) || "Numeric values are not allowed", // No numeric values allowed
      ],

      uploadInfoInputs: [],
      dateMenu: "",
      maxDate: "",
      uploadFile: {},
      document: {},
      sumGetTotalLength: 0,
      getTotalFormLength: [],
      flag: "",
      // showNextButton: false,
      // showSubmitButton: true,
      geoIdFordocumentInfoList: "",
      base64Data: "",
      // updateIdNames: "",
      uploadedFiles: [],
      countryNameList: [],
      documentInfoList: [],
      showIndex: 0,
      countryGeoId: "",
      // FormType: "PERSONAL",
      geoIDSelected: "",
      authToken: "",
      selectedIdentity: "",
      uploadFilesArray: [],
      countryCodeOptions: [],
      outList: [],
      // uploadedDocumentStatus: false,
      fileLoader: false,
      dialogDelete: false,
      showPhoneNumber: false,
      showCountryCode: false,
      instructionCount: 0,
    };
  },
  created() {
    this.addRow(0);
    // var jsonDAtaNew = JSON.parse(localStorage.getItem("JsonNextToSave"));
    this.authToken = localStorage.getItem("authToken");
  },
  mounted() {
    this.countryCodeOptions = countryCodeOptions;
    this.maxDate = new Date().toISOString().substr(0, 10);
    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.getCartItem();
    this.getBilling();
    this.getUserDetail();
  },
  methods: {
    getFlagURL,
    // getGeoID(geoIdRetrive) {
    //   // console.log("geoIdRetrive=========", geoIdRetrive);
    //   this.geoIDSelected = geoIdRetrive;
    // },

    // updateValueData(value) {
    //this.$refs.form.validate()
    // },

    // valueChangeCheck(idName) {
    //   this.updateIdNames = idName;
    // },

    changeForm() {
      // this.FormType = "";
      // this.FormType = formName;
      // console.log("this.FormType", this.FormType);
      // console.log("this.document", this.document);
      this.fetchDocumentInfoIdList();
      // else {
      //   this.getDocumentInfo();
      //   this.radioGroup = this.FormType;
      // }
    },
    //get all country data in getcart items
    async getCartItem() {
      this.isLoading = true;
      this.submitForm = false;
      try {
        let response = await shoppingCartAPI.getCartItem();
        this.geoIDSelected = response.geoId;
        this.instructionCount = response.instructions.length;
        console.log("==instructionCount==========" + this.instructionCount);
        if (response.instructions.length != 0) {
          this.submitForm = false;
          response.instructions.forEach((element) => {
            var shortCountryName;
            if (element) {
              // shortCountryName = element.substring(
              //   element.indexOf(">") + 1,
              //   element.lastIndexOf("Toll-free")
              // );
              var shortCountryName = element.countryName.toUpperCase();
              if (shortCountryName) {
                this.flag = true;
              }
            }
            var countryNameData = {
              countryName: element.countryName.toUpperCase(),
              countryGeoId: element.countryGeoId,
            };
            this.countryNameList.push(countryNameData);
            this.countryGeoId = this.countryNameList[0].countryGeoId;
            // this.countryNameList.forEach((result) => {
            //   this.geoIDSelected = result.countryGeoId;
            // });
            this.showIndex = 0;
          });
        } else {
          // console.log("this.submitForm ", this.submitForm);

          this.submitForm = true;
          this.flag = null;
        }
        this.cartList = [];
        const quantityMap = {};
        let items = response.cartItemList;
        const updatedItems = [];

        items.forEach((item) => {
          const { areaCode, didType, quantity, facilityId, cityGeoId} = item;
          const key = `${areaCode}_${didType}_${facilityId}${cityGeoId ? `_${cityGeoId}` : ''}`;
          // If the key exists in the map, add the quantity; otherwise, initialize with the quantity
          quantityMap[key] = (quantityMap[key] || 0) + parseFloat(quantity);

         // If the item doesn't exist in the updatedItems array, add it with the updated quantity
          const existingItemIndex = updatedItems.findIndex((existing) => {
            if (cityGeoId && cityGeoId.trim() !== '') {
              // Match with cityGeoId if it exists and is not empty
              return (
                existing.areaCode === areaCode &&
                existing.didType === didType &&
                existing.facilityId === facilityId &&
                existing.cityGeoId === cityGeoId
              );
            } else {
              // Match without cityGeoId
              return (
                existing.areaCode === areaCode &&
                existing.didType === didType &&
                existing.facilityId === facilityId
              );
            }
          });

          if (existingItemIndex === -1) {
            updatedItems.push({ ...item, quantity: quantityMap[key] });
          } else {
            // If the item already exists, update the quantity
            updatedItems[existingItemIndex].quantity = quantityMap[key];
          }
        });

        this.cartList = updatedItems;

        // Log the result
        this.grandTotal = response.cartGrandTotal;
        if (response.instructions && response.instructions.length > 0) {
          for (var i = 0; i < response.instructions.length; i++) {
            this.instructions[response.instructions[i]["countryGeoId"]] =
              response.instructions[i]["instruction"];
          }
        }
        for (let item of this.cartList) {
          this.totalDIDNumbers += Number(item.quantity);
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    async getUserDetail() {
      let userData = {
        accountId: this.userDetail.accountId,
        userId: this.userDetail.userId,
      };
      try {
        let response = await profileAPI.getUserDetail(userData);
        this.userData = response.userDetail;

        console.log("userDatauserData", this.userData);
        if (
          this.userData?.isPhoneVerified == "Y" &&
          (this.userData?.isIdentityVerified === "S" ||
            this.userData?.isIdentityVerified === "Y")
        ) {
          this.$set(this, "showCHeckout", false); // This ensures reactivity
          this.$forceUpdate(); // Force Vue to re-render the component
        }

        let identityVerificationSubmited =
          !this.userData?.isIdentityVerified ||
          this.userData?.isIdentityVerified == "S";
        //this.userData?.isPhoneVerified == "Y";

        let phoneVerificationFailed =
          !this.userData?.isPhoneVerified ||
          this.userData?.isPhoneVerified === "N"; // Strict comparison with 'N'

        console.log("Phone Verification Failed: ", phoneVerificationFailed); // This should output false if it's "Y"

        let identityVerificationFailed =
          !this.userData?.isIdentityVerified ||
          ["N", "R", "RS"].includes(this.userData?.isIdentityVerified); // Use an array for multiple checks

        console.log(
          "Identity Verification Failed: ",
          identityVerificationFailed
        );

        console.log("phoneVerificationFailed", phoneVerificationFailed);

        if (!phoneVerificationFailed) {
          this.$set(this, "phoneNumberVerified", true); // This ensures reactivity
          this.$forceUpdate(); // Force Vue to re-render the component
        }

        if (!identityVerificationFailed) {
          this.$set(this, "isIdentityVerified", false);
          this.$forceUpdate();
        }

        if (phoneVerificationFailed || identityVerificationFailed) {
          this.mobileVerificationErrorForShoppingPopupShopping = true;

          if (phoneVerificationFailed) {
            this.mobileVerificationErrorForShopping =
              "Please verify your mobile number to Checkout!";
            this.$set(this, "phoneNumberVerified", false); // This ensures reactivity
            this.phoneNumberVerified = false; // Change this to false (since failed)
            this.$forceUpdate(); // Force Vue to re-render the component
          } else if (identityVerificationFailed) {
            this.mobileVerificationErrorForShopping =
              "Please verify your Identity to Checkout!";
            this.isIdentityVerified = true;
            this.$set(this, "isIdentityVerified", true);
            this.$forceUpdate();
          }
        } else {
          this.mobileVerificationErrorForShoppingPopupShopping = false;
        }

        if (identityVerificationSubmited) {
          this.identityVerificationErrorPopupShopping = true;
          this.mobileVerificationErrorForShopping =
            "Documents Submitted - Pending Verification at Admin level!";
        } else {
          this.identityVerificationErrorPopupShopping = false;
        }
      } catch (error) {}
    },

    async getBilling() {
      this.isLoading = true;
      let userDetail = JSON.parse(localStorage.getItem("userDetail"));
      try {
        let response = await shoppingCartAPI.getBilling({
          accountId: userDetail.accountId,
          partyId: userDetail.partyId,
        });
        this.accountBillingList = response.billingAccountList;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    async modifyCart() {
      this.isLoading = true;
      try {
        await shoppingCartAPI.modifyCart({
          removeSelected: "true",
          selectedItem: this.removeCartData.index,
          quantity: Number(this.removeCartData.quantity),
        });
        this.getCartItem();
        this.getBilling();

        this.$root.$emit("MODIFY_CART", {
          quantity: -Number(this.removeCartData.quantity),
        });
        this.showDialog = false;
        this.showClearDialog = false;
        this.$router.go(this.$router.currentRoute);
      } catch (error) {}
    },

    async destroyCart() {
      this.isLoading = true;
      try {
        await shoppingCartAPI.destroyCart({
          removeSelected: "true",
        });
        this.getCartItem();
        this.getBilling();
        this.showDialog = false;
        this.showClearDialog = false;
        this.$router.go(this.$router.currentRoute);
      } catch (error) {}
    },

    async updateCart(index, quantity) {
      this.isLoading = true;
      try {
        await shoppingCartAPI.modifyCart({
          removeSelected: "false",
          selectedItem: index,
          quantity: Number(quantity),
        });
        // this.getCartItem();
        // this.getBilling();
        this.$router.go(this.$router.currentRoute);
      } catch (error) {}
    },

    showModal(index, quantity) {
      this.showDialog = true;
      this.removeCartData = {
        index: index,
        quantity: quantity,
      };
    },

    showClearModal() {
      this.showClearDialog = true;
      this.removeCartData = {};
    },

    // closeUserForm() {
    //   this.userFormDialog = false;
    //   this.document = {};
    //   this.radioGroup = {};
    //   this.input = {};
    //   //this.userDocumentInfo = [];
    //   this.uploadInfoInputs = [
    //     {
    //       uploadFileKey: null,
    //       uploadFile: null,
    //     },
    //   ];
    //   this.document = {};
    //   this.outList = [];
    //   //this.documentInfoList = [];
    // },

    closeCheckOutDialog() {
      this.showCheckoutDialog = false;
      this.$root.$emit("MODIFY_CART", {
        quantity: 0,
      });
      this.$root.$emit("SHOW_SNACKBAR", {
        text: "Your order is placed",
        color: "success",
      });
      location.href = "/orders";
    },
    //upload document functionality
    uploadFiles() {
      this.uploadedFiles = [];
      if (this.uploadInfoInputs.length != 0) {
        for (let index = 0; index < this.uploadInfoInputs.length; index++) {
          const element = this.uploadInfoInputs[index];
          if (
            (element &&
              element.uploadFile &&
              element.uploadFile.type &&
              element.uploadFile.type == "image/jpeg") ||
            element.uploadFile.type == "image/jpg" ||
            element.uploadFile.type == "application/pdf" ||
            element.uploadFile.type == "image/png"
          ) {
            element.uploadFiles = {};
            var rawData = [];
            var this_ = this;
            var files = [];
            //  making baseUrl of upload documents
            if (element.uploadFileKey != null) {
              files.push(element.uploadFile);
              var reader = new FileReader();
              var file = "updating";
              reader.onloadend = function (evt) {
                // console.log("evt--------------", evt);
                var data = evt.target.result;
                // console.log("data-------", data);
                file = data.split(",");
                // console.log("file", file);
                var img = new Image();
                if (element.uploadFile.type != "application/pdf") {
                  img.onload = function () {
                    // console.log("enterrrrrrrrrrrrrrrrr-----------canvas");
                    var canvas = document.createElement("canvas");
                    var ctx = canvas.getContext("2d");
                    // Resize canvas
                    var MAX_WIDTH = 1000;
                    var MAX_HEIGHT = 800;
                    var width = img.width;
                    var height = img.height;
                    if (width > height) {
                      if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                      }
                    } else {
                      if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                      }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    // Draw image on canvas
                    ctx.drawImage(img, 0, 0, width, height);
                    // Get compressed data URL
                    var compressedDataUrl = canvas.toDataURL("image/jpeg", 1.0);
                    var rawData = compressedDataUrl.split(",")[1];
                    // console.log("======raw data", rawData);
                    //rawData = file[1];
                    // console.log("this_.geoIDSelected", this_.geoIDSelected);
                    if (
                      element.uploadFileKey != null ||
                      element.uploadFileKey != undefined
                    ) {
                      element.uploadFiles["uploadedFile"] = rawData;
                      element.uploadFiles["fileName"] = element.uploadFileKey;
                      element.uploadFiles["geoId"] = this_.geoIDSelected;
                      element.uploadFiles["documentType"] = this_.radioGroup;
                      element.uploadFiles["mimeTypeId"] =
                        element.uploadFile.type;
                      this_.uploadedFiles.push(element.uploadFiles);
                      // localStorage.setItem(
                      //   "uploadObject",
                      //   JSON.stringify(this_.uploadedFiles)
                      // );
                    }
                  };
                } else {
                  element.uploadFiles["uploadedFile"] = file[1];
                  element.uploadFiles["fileName"] = element.uploadFileKey;
                  element.uploadFiles["geoId"] = this_.geoIDSelected;
                  element.uploadFiles["documentType"] = this_.radioGroup;
                  element.uploadFiles["mimeTypeId"] = element.uploadFile.type;
                  this_.uploadedFiles.push(element.uploadFiles);
                }
                img.src = data;
              };
              if (files[0] != null) {
                reader.readAsDataURL(files[0]);
              }
            }
          } else {
            this.uploadInfoInputs[index].uploadFile = "";
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "File extension should be '.jpeg','.jpg',.pdf or '.png'",
              color: "error",
            });
          }
        }
        //store all uploaded imges in this.uploadFiles array
      }
    },

    // async uploadBase64() {
    //   // console.log("this_.uploadedFiles", this.uploadedFiles);
    //   this.fileLoader = true;
    //   let filesPayload = {
    //     uploadedFiles: this.uploadedFiles,
    //     partyId: this.userDetail.partyId,
    //     selectedDocType: this.radioGroup,
    //   };
    //   try {
    //     let response = await shoppingCartAPI.uploadBase64(filesPayload);
    //     console.log("response=============", response);
    //     if (response) {
    //       this.uploadedDocumentStatus = true;
    //     }
    //     this.fileLoader = false;
    //   } catch (error) {
    //     this.$root.$emit("SHOW_SNACKBAR", {
    //       text: "something wrong",
    //       color: "error",
    //     });
    //     this.fileLoader = false;
    //   }
    // },
    // next to save data
    // nextToSaveData(checkSubmit, indexofCountryName, country) {
    //   this.$refs.form.validate();
    //   //store all documnets data in this.documentInfoList array
    //   this.submitUserForm(checkSubmit, country);
    //   if (checkSubmit != "Submit") {
    //     this.countryGeoId = "";
    //     var getcountryNameListID = indexofCountryName + 1;
    //     //pass geo-id into getDocumentInfo API
    //     for (let index = 0; index < this.countryNameList.length; index++) {
    //       const element = this.countryNameList[index];
    //       if (getcountryNameListID == index) {
    //         this.countryGeoId = element.countryGeoId;
    //         this.getDocumentInfo();
    //       }
    //     }
    //   }
    // },
    // get documentInfoList data
    getSelectedDocumentInfoData(documentInfoId) {
      var quantity = "";
      for (let index = 0; index < this.cartList.length; index++) {
        const element = this.cartList[index];
        // var countryName = this.countryNames.toUpperCase().trim();
        // var cartElementName = element.countryName.toUpperCase().trim();
        // if (cartElementName) {
        //   if (countryName == cartElementName) {
        //     quantity = element.quantity;
        //   }
        // }
        if (element.quantity != "" && element.quantity > 0) {
          //   // Find the index of the first underscore
          var underscoreIndex = element.facilityId.indexOf("_");

          this.documentInfoList.push({
            numberOfDID: element.quantity,
            documentInfoId: documentInfoId,
            geoId: element.facilityId.substring(0, underscoreIndex),
          });
        }
      }
      //if selecteds user old created document data
      // if (this.document.documentInfoId) {
      //   this.documentInfoList.push({
      //     numberOfDID: quantity,
      //     documentInfoId: this.document.documentInfoId,
      //     geoId: this.geoIDSelected.slice(0, 3),
      //   });
      // localStorage.setItem(
      //   "documentInfoListObject",
      //   JSON.stringify(this.documentInfoList)
      // );
      // this.document.documentInfoId = "";
      // }
      // else {
      //   //if  user created new document data
      //   this.documentInfoList.push({
      //     numberOfDID: quantity,
      //     documentInfoId: this.document.documentInfoId,
      //     geoId: this.document.geoId,
      //   });
      //   this.document.documentInfoId = "";
      //   // localStorage.setItem(
      //   //   "documentInfoListObject",
      //   //   JSON.stringify(this.documentInfoList)
      //   // );
      // }
    },
    //call function when click on submit button
    async submitUserForm(documentInfoId) {
      // this.countryGeoId = country.countryGeoId;
      // this.countryNames = country.countryName;
      this.userDocumentInfo.documentInfoType = this.radioGroup;
      // var fillForm = null;
      // var userDocumentInfoArray = Object.values(this.userDocumentInfo);
      // if (this.getTotalFormLength.length != 0) {
      //   for (var i = 0; i < this.getTotalFormLength.length; i++) {
      //     if (this.getTotalFormLength[i] != userDocumentInfoArray[i]) {
      //       fillForm = true;
      //     } else if (this.getTotalFormLength[i] == userDocumentInfoArray[i]) {
      //       if (fillForm != true) {
      //         if (checkSubmit == "Submit") {
      //           this.showIndex = 0;
      //         }
      //       }
      //     }
      //   }
      // } else {
      //   fillForm = true;
      // }

      // console.log(
      //   "this.uploadedFiles.length",
      //   this.uploadedFiles
      //   "this.uploadedFiles",
      //   this.uploadedFiles,
      //   "this.userDocumentInfo",
      //   this.userDocumentInfo
      // );

      // console.log(
      //   "document.documentInfoId",
      //   this.document.documentInfoId
      // );
      var submitForm = false;
      // console.log("this.submitForm", this.submitForm);
      // if already uploaded files
      if (this.submitForm) {
        submitForm = true;
      } else if (this.outList.length != 0) {
        submitForm = true;
      } else if (this.uploadedFiles.length != 0) {
        submitForm = true;
      }

      // console.log(
      //   "submitForm===========>",
      //   submitForm,
      //   "this.outList==========",
      //   this.outList,
      //   "this.uploadedFiles============",
      //   this.uploadedFiles
      // );
      var getData = {};

      if (this.$refs.form.validate()) {
        if (submitForm) {
          // console.log("fillForm===========", fillForm);
          // if (fillForm == true) {
          this.userDocumentInfo.uploadedFiles = this.uploadedFiles;
          this.userDocumentInfo.selectedDocType = this.radioGroup;
          this.userDocumentInfo.geoId = this.geoIDSelected;
          this.userDocumentInfo.partyId = this.userDetail.partyId;
          // if (this.countryGeoId) {
          //   this.userDocumentInfo.geoId = this.countryGeoId.slice(0, 3);
          // }
          // if (this.userDocumentInfo.documentInfoId) {
          //   delete this.userDocumentInfo.documentInfoId;
          // }

          if (this.userDocumentInfo.dateOfBirth) {
            console.log(
              "fillForm====1372=======",
              this.userDocumentInfo.dateOfBirth
            );
            getData = {
              dateOfBirth: this.formatDate(this.userDocumentInfo.dateOfBirth),
            };
          }
          this.submitLoader = true;
          try {
            let response = await shoppingCartAPI.uploadDocumentInfo({
              ...this.userDocumentInfo,
              ...getData,
            });
            // this.document.documentInfoId = response.outMap.documentInfoId;
            this.document.documentInfoId = documentInfoId ? documentInfoId : "";
            if (
              response.outMap.documentInfoId != null ||
              response.outMap.documentInfoId != undefined
            ) {
              this.document.documentInfoId = response.outMap.documentInfoId;
              documentInfoId = this.document.documentInfoId;
            }
            this.document.geoId = response.outMap.geoId;
            this.submitLoader = false;
            submitForm = false;
            this.submitForm = false;
            this.geoIDSelected = this.document.geoId;
            // this.uploadFiles();
            this.getSelectedDocumentInfoData(documentInfoId);
            // console.log("this.document", this.document);
            // this.showIndex = 0;
            this.userFormDialog = false;
            if (!this.selectedIdentity) {
              this.outList = [];
              this.uploadedFiles = [];
              this.uploadInfoInputs = [
                {
                  uploadFileKey: null,
                  uploadFile: null,
                },
              ];
            }
            // if (checkSubmit == "Submit") {
            //   this.geoIDSelected = this.document.geoId;
            //   // this.uploadFiles();
            //   this.getSelectedDocumentInfoData();
            //   this.showIndex = 0;
            //   this.userFormDialog = false;
            //   this.uploadInfoInputs = [
            //     {
            //       uploadFileKey: null,
            //       uploadFile: null,
            //     },
            //   ];
            // } else {
            //   this.geoIDSelected = this.document.geoId;
            //   // this.uploadFiles();
            //   this.getSelectedDocumentInfoData();
            //   this.showIndex++;
            //   this.uploadInfoInputs = [
            //     {
            //       uploadFileKey: null,
            //       uploadFile: null,
            //     },
            //   ];
            // }
            // fillForm = null;

            // if (Object.keys(this.document).length != 0) {
            //   this.$refs.form.reset();
            // }
            // // set empty form
            // if (Object.keys(this.userDocumentInfo).length != 0) {
            //   this.$refs.form.reset();
            // }

            this.$root.$emit("SHOW_SNACKBAR", {
              text: response.responseMessage,
              color: "success",
            });
          } catch (error) {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "something wrong",
              color: "error",
            });
            this.submitLoader = false;
          }
          // } else {
          //   // this.uploadFiles();
          //   this.getSelectedDocumentInfoData();
          //   if (checkSubmit == "Submit") {
          //     this.showIndex = 0;
          //     this.getDocumentInfo(checkSubmit);
          //     this.document = {};
          //     this.getDocumentList = [];
          //     this.$refs.form.reset();
          //   } else {
          //     this.showIndex++;
          //     this.$refs.form.reset();
          //     this.uploadInfoInputs = [
          //       {
          //         uploadFileKey: null,
          //         uploadFile: null,
          //       },
          //     ];
          //   }
          // }
        } else {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Please upload documents!",
            color: "error",
          });
        }
      }
    },
    formatDate(SelectedDate) {
      var date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let seconds = date.getSeconds();
      return `${SelectedDate} ${hours}:${minutes}:${seconds}`;
    },
    // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    deleteItem() {
      localStorage.removeItem("selectedGeoID"); // remove geoid on back click
    },

    deleteGeoId() {
      localStorage.removeItem("selectedGeoID"); // remove geoid on back click
    },
    //fetch document data by usr geoID
    async fetchDocumentInfoIdList() {
      this.isLoading = true;
      // var getDocumentList = [];
      // this.getDocumentList = [];
      if (!this.selectedIdentity) {
        this.document = {};
      }
      try {
        // getDocumentInfo
        // let response = await shoppingCartAPI.getDocumentInfo({
        //   partyId: this.userDetail.partyId,
        //   geoId: this.countryGeoId.slice(0, 3),
        //   documentInfoType: this.FormType,
        // });
        // getDocumentList = response.documentInfoList;
        // var userDocumentInfo = [];
        // for (let index = 0; index < getDocumentList.length; index++) {
        //   var element = getDocumentList[index];
        //   if (element.firstName) {
        //     element.name = element.firstName + " " + "(" + element.country + ")";
        //   }
        //   userDocumentInfo.push(element);
        // }
        // this.getDocumentList = this.IndentiyDocumentList.concat(userDocumentInfo);
        // this.userFormDialog = true;
        // this.isLoading = false;
        // if (checkSubmit == "Submit") {
        //   this.countryNameList.reverse();
        //   this.userFormDialog = false;
        // }
        let response = await shoppingCartAPI.fetchDocumentInfoIdList({
          partyId: this.userDetail.partyId,
          documentInfoType: this.radioGroup,
        });
        // console.log("response======", response);
        this.getDocumentList = this.IndentiyDocumentList.concat(
          response.outMapList
        );

        // if (!this.selectedIdentity) {
        // set empty form
        if (Object.keys(this.userDocumentInfo).length != 0) {
          this.$refs.form.reset();
        }
        if (Object.keys(this.document).length != 0) {
          this.$refs.form.reset();
        }
        this.outList = [];
        this.uploadedFiles = [];
        this.uploadInfoInputs = [
          {
            uploadFileKey: null,
            uploadFile: null,
          },
        ];
        // }

        this.userFormDialog = true;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },
    // add row for multiple im
    addRow(index) {
      if (this.uploadInfoInputs.length < 6) {
        this.uploadInfoInputs.push({
          uploadFileKey: null,
          uploadFile: null,
        });
      } else {
        if (this.uploadInfoInputs.length != 0) {
          this.$root.$emit("SHOW_SNACKBAR", {
            text: "Maximum add six documents!",
            color: "error",
          });
        }
      }
    },
    // delete upload doucument
    deleteRow(index, input) {
      this.uploadInfoInputs.splice(index, 1);
      this.uploadedFiles = [];
      this.uploadInfoInputs.forEach((element) => {
        this.uploadedFiles.push(element.uploadFiles);
      });
      // localStorage.setItem("uploadObject", JSON.stringify(this.uploadedFiles));
      this.getCartItem();
    },
    // call on selected uploaded dcoument oprion in select box
    getItemText(selectedDocumentData) {
      // console.log("userDocumentInfo======", this.userDocumentInfo);
      // console.log("this.document.documentInfoId", selectedDocumentData);
      // this.document.documentInfoId = selectedDocumentData.documentInfoId;
      if (selectedDocumentData.documentInfoId == "createNew") {
        // this.userDocumentInfo.statusId = "DOC_UNAPPROVED";
        this.$refs.form.reset();
        this.userDocumentInfo = {};
        this.uploadInfoInputs = [
          {
            uploadFileKey: null,
            uploadFile: null,
          },
        ];
        this.outList = [];
        // this.submitForm = false;
      } else {
        // const checkValidDate = moment(
        //   selectedDocumentData.dateOfBirth,
        //   "YYYY-MM-DD",
        //   true
        // ).isValid();
        // console.log("checkValidDate ", checkValidDate);
        // if (checkValidDate) {
        //   this.userDocumentInfo = selectedDocumentData;
        // } else {
        //   this.converUnixToDate(selectedDocumentData);
        // }
        // this.getTotalFormLength = Object.values(selectedDocumentData);
        this.getDocumentInfo(selectedDocumentData);
      }
    },
    // get documet data
    async getDocumentInfo(selectedDocumentData) {
      this.isLoading = true;
      // var getDocumentList = [];
      // this.getDocumentList = [];
      // this.document = {};
      // console.log(
      //   "this.radioGroup",
      //   this.radioGroup,
      //   "selectedDocumentData",
      //   selectedDocumentData
      // );
      try {
        // getDocumentInfo
        let response = await shoppingCartAPI.getDocumentInfo({
          partyId: this.userDetail.partyId,
          geoId: this.countryGeoId.slice(0, 3),
          documentInfoType: this.radioGroup,
          documentInfoId: selectedDocumentData.documentInfoId,
        });
        // getDocumentList = response.documentInfoList;
        // var userDocumentInfo = [];
        // for (let index = 0; index < getDocumentList.length; index++) {
        //   var element = getDocumentList[index];
        //   if (element.firstName) {
        //     element.name = element.firstName + " " + "(" + element.country + ")";
        //   }
        //   userDocumentInfo.push(element);
        // }
        // console.log("respaonse:+++++++++++", response);
        this.selectedIdentity = response.name;
        if (response.documentInfoList.length == 0) {
          this.$refs.form.reset();
        } else {
          this.userDocumentInfo = response.documentInfoList[0];
          if (this.userDocumentInfo?.statusId == "DOC_APPROVED") {
            if (!this.userDocumentInfo?.contactNumber) {
              this.showPhoneNumber = true;
            }
            if (!this.userDocumentInfo?.countryCode) {
              this.showCountryCode = true;
            }
          }
        }

        this.outList = response.outList;
        if (this.userDocumentInfo && this.userDocumentInfo.dateOfBirth) {
          this.converUnixToDate(this.userDocumentInfo.dateOfBirth);
        }

        // this.userFormDialog = true;
        this.isLoading = false;
        // if (checkSubmit == "Submit") {
        //   this.countryNameList.reverse();
        //   this.userFormDialog = false;
      } catch (error) {
        this.isLoading = false;
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "something wrong",
          color: "error",
        });
      }
    },
    // convert timestamp to date format
    converUnixToDate(dateOfBirth) {
      if (dateOfBirth) {
        const dateString = moment.unix(dateOfBirth / 1000).format("YYYY-MM-DD");
        this.userDocumentInfo.dateOfBirth = dateString;
        // return dateString;
      }
    },

    //checkout functionality
    async checkOut() {
      if (
        this.instructionCount &&
        this.instructionCount > 0 &&
        this.document.documentInfoId == undefined
      ) {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: "Please select identity using upload documents button!",
          color: "error",
        });
      } else {
        // if (this.document.documentInfoId || (this.instructionCount && this.instructionCount === 0)) {
        this.isLoading = true;
        this.userFormDialog = false;
        try {
          let checkOutData = {
            billingAccountId: this.billingAccountId,
            shippingMethod: "NO_SHIPPING@_NA_",
            checkOutPaymentId: "EXT_BILLACT",
          };

          await shoppingCartAPI.checkOut(checkOutData);
          let processOrderData = {
            accountId: this.userDetail.accountId,
            authToken: this.authToken,
            billingAccountId: this.billingAccountId,
            checkOutPaymentId: "EXT_BILLACT",
            shippingMethod: "NO_SHIPPING@_NA_",
          };

          // var retrievedObject = localStorage.getItem("uploadObject");
          // retrievedObject = JSON.parse(retrievedObject);

          // if (retrievedObject) {
          //   processOrderData.uploadedFiles = retrievedObject;
          // }

          // var retrievedDocInfoObject = localStorage.getItem("documentInfoListObject");

          // retrievedDocInfoObject = JSON.parse(retrievedDocInfoObject);

          // if (retrievedDocInfoObject) {
          processOrderData.documentInfoList = this.documentInfoList;
          // }

          var responseProcessOrder = await shoppingCartAPI.processOrder(
            processOrderData
          );
          if (responseProcessOrder) {
            //this.uploadFile = {};
            this.showCheckoutDialog = true;
            this.$refs.form.reset();
            this.$root.$emit("MODIFY_CART", {
              quantity: 0,
            });
            // window.localStorage.clear();
            this.totalDIDNumbers = 0;
            this.isLoading = false;
            this.getCartItem();
          }
        } catch (error) {
          this.isLoading = false;
          if (error.data.messageDetail) {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: error.data.messageDetail,
              color: "error",
            });
          } else {
            this.$root.$emit("SHOW_SNACKBAR", {
              text: "something wrong",
              color: "error",
            });
          }
        }
      }
      // } else {
      //   if(this.instructionCount && this.instructionCount > 0){
      //     this.$root.$emit("SHOW_SNACKBAR", {
      //       text: "Please select identity using upload documents button!",
      //       color: "error",
      //     });
      //   }
      // }
    },

    // delete document file
    deleteDialog(deletefile) {
      this.deletefileData = deletefile;
      this.dialogDelete = true;
    },
    // delete uploaded files
    async deleteItemConfirm() {
      this.isLoading = true;
      let deleteDocPayload = {
        partyId: this.userDetail.partyId,
        documentInfoType: this.radioGroup,
        documentInfoId: this.deletefileData.documentInfoId,
        fileName: this.deletefileData.fileName,
      };
      let response = await shoppingCartAPI.deleteFile(deleteDocPayload);
      if (response) {
        this.isLoading = false;
        this.dialogDelete = false;
        this.getDocumentInfo(this.deletefileData);
      }
    },
  },
};
</script>

<style scoped>
.text-capitalize {
  text-transform: capitalize;
}

.error-message {
  color: red;
}

.verify-button {
  background-color: pink;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
}
</style>
